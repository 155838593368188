import React from "react"
import { Link, graphql } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import red from "@material-ui/core/colors/red"
import amber from "@material-ui/core/colors/amber"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import NewSiteNotice from "../components/newSiteNotice"

const useStyles = makeStyles(theme => ({
  content: {
    width: "100%",
  },
  heroContent: {
    margin: "auto",
    padding: theme.spacing(8, 0, 6),
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  divider: {
    paddingTop: theme.spacing(1),
  },
  cardGrid: {
    marginLeft: "-15px",
    marginRight: "-15px",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
    width: "calc(100% + 30px)",
  },
  cardInnerGrid: {
    display: "flex",
    alignItems: "space-between",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "50%",
    background: "black",
  },
  cardContent: {
    flexGrow: 1,
  },
}))

const heroRender = classes => {
  return (
    <div className={classes.heroContent}>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        Learn Cantonese for free.
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        Our free courses cover Cantonese speaking, listening, pronunciation,
        vocabulary and grammar, and Hong Kong culture.
      </Typography>
    </div>
  )
}

const gridRender = (courseList, classes) => {
  const colors = [red[600], amber[600]]

  return (
    <div>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          {courseList.map((course, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              key={course.courseId}
              className={classes.cardInnerGrid}
            >
              <CardActionArea
                component={Link}
                to={`/${course.slug}`}
                style={{ textDecoration: "none" }}
              >
                <Card variant="outlined" className={classes.card}>
                  <CardMedia
                    src="/"
                    className={classes.cardMedia}
                    style={{ backgroundColor: colors[index] }}
                  />
                  <CardContent className={classes.cardContent}>
                    {/* <Box pb={1}> */}
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: 500 }}
                      gutterBottom
                    >
                      {course.courseTitle}
                    </Typography>
                    {/* </Box> */}
                    <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
                      {course.courseShortDescription}
                    </Typography>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

const IndexPage = ({ data }) => {
  const classes = useStyles()

  // Process data
  const courseList = data.CourseList.edges.reduce(
    (acc, cur) =>
      acc.concat({ ...cur.node.exports.coursedata, ...cur.node.frontmatter }),
    []
  )
  const pageInfo = {
    type: "page",
    courseId: null,
    sectionId: null,
    lessonId: null,
  }

  return (
    <Layout pageInfo={pageInfo} courseList={courseList}>
      <SEO index title="Open Cantonese: Learn Cantonese for free" />

      <div className={classes.content}>
        <div
          style={{
            height: "12px",
          }}
        />
        <NewSiteNotice />
        {heroRender(classes)}
        {gridRender(courseList, classes)}
      </div>

      <div className={classes.divider}>
        <Divider light />
      </div>
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    CourseList: allMdx(
      filter: { frontmatter: { type: { eq: "course" } } }
      sort: { fields: frontmatter___courseId, order: ASC }
    ) {
      edges {
        node {
          exports {
            coursedata {
              courseId
              courseShortTitle
              courseTitle
              courseShortDescription
            }
          }
          frontmatter {
            slug
            type
          }
        }
      }
    }
  }
`
